import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CategoryTitle from "../components/index/categorytitle"
import { Link } from "gatsby"
import AsideItem from "../components/aside/item"

const Security = () => (
  <Layout>
    <SEO title="Security" />
    <div id="security" className="inlinePage footerFixed center-h">
      <div className="container-fluid w80 ma pt5 pb55 col-xs-pt15">
        <CategoryTitle title="SERENAY" subTitle="Privacy & Security" description="Privacy & Security" isDark={false} position="inline" />
        <div className="row">
        	<div className="w20 left pt15 mt15 col-sm-w100 pr45 col-sm-pr0">
        		<aside className="w100 left pos-r">
        			<ul className="w100 left fs14 fw6" style={{letterSpacing: "0.5px"}}>
			        	<AsideItem to="/corporate" title="Our story" icon="icon-serenay" />
			        	<AsideItem to="/ID" title="Corporate ID" icon="icon-kariyer1" />
			        	<AsideItem to="/security" title="Privacy & Security" icon="icon-shield2" />
			        </ul>
        		</aside>
        	</div>
        	<div className="w80 col-sm-w100 left bl1 bc1 col-sm-bl0 col-sm-pl0 pt15 mt15 pl45">
	        	<article className="w100 left fs18">
	  					<p><b>Serenay</b> pays special attention to ensuring the confidentiality and security of the information of its Members and Users. This issue is a requirement of <b>Serenay</b>'s sensitivity in this matter, but also a legal obligation of <b>Serenay</b> in accordance with the relevant legislation. Within the framework of our approach to protect the information of our members and to take utmost care in complying with the legal legislation, detailed arrangements are made in the <b>Serenay</b> legislation in order to ensure that all our employees show the necessary sensitivity at the highest level, and all kinds of activities carried out. The protection of Member information in the application and process is taken into consideration.</p>
	  					<p>Your personal data, which we save by automatic or non-automatic methods and share with us electronically during the visit of the Site, will first be used to ensure that your requests are fulfilled and then to serve you better as <b>Serenay</b>. In addition, in accordance with the provisions of the Privacy Policy (“Privacy Policy”) on the Site, we take the necessary care regarding the protection and security of your personal data.</p>
	  					<p>All information, including the personal data of our Members, is not shared with third parties in any way or form, without the consent of the Member, except for the legal institutions and authorities that have the authority to access this information in accordance with the provisions of the relevant legislation.</p>
	  					<p>The commitments contained in this policy are valid for the information shared on this Site and through other channels of <b>Serenay</b>, the privacy principles, ethical principles and terms of use of the websites visited on the websites linked on this site and on other websites are valid. <b>Serenay</b> is not responsible for material/moral losses that may be incurred on these sites.</p>
	  					<p>If you share your personal information (address, telephone, fax, e-mail address, etc.) with <b>Serenay</b>, such information will only be sent to you regarding the provision of the service you requested, change, information, use and maintenance services and the renewal of the contract. It will not be shared with third parties or institutions except for legal obligations or your permission. If you are included in our approved database, messages may be sent to you for promotion, marketing and promotional activities regarding the Site products and services. If you do not want to receive e-mails sent by <b>Serenay</b>, for example, by using the right to refuse; At the bottom of the Sign Up page, <b>Serenay</b> <a href='https://www.serenay.net.tr'>www.serenay.net.tr</a> regarding the promotion of new products and services If you do not wish to receive any e-mails, please click here. By clicking on the link, you can unsubscribe from our list of the relevant channel.</p>
	  					<p>Protection of Personal Data</p>
	  					<p>In this Privacy Policy, “your personal data” means any information or pieces of information that make your identity specific or identifiable.</p>
	  					<p>As <b>Serenay</b>, we undertake to comply with the following principles in terms of obtaining, recording, storing, preserving, changing, rearranging, explaining, selling or otherwise processing your personal data.</p>
	  					<p>Your personal data will always be processed correctly, in accordance with the law and honesty.</p>
	  					<p>Your personal data may be processed and/or transferred to third parties by obtaining their consent or in other cases stipulated in the legislation.</p>
	  					<p>Your personal data may be processed for specific, explicit and legitimate purposes.</p>
	  					<p>Your personal data will be collected and processed in a limited way in connection with the aforementioned purposes.</p>
	  					<p>You will be provided with the opportunity to update your personal data when necessary.</p>
	  					<p>Your personal data will be retained for as long as necessary for the purpose for which they are processed.</p>
	  					<p><b>INFORMATION</b></p>
	  					<p>Data Shared by You</p>
	  					<p>In addition to automatically obtained data, data shared by you are also processed. This data is used in line with the purpose for which you provide it and on a limited basis for this purpose. The main examples of the purposes of using the said data can be listed as follows:</p>
	  					<p>
	  						<ol>
	  							<li>When you register on the site, you obtain your personal data through the forms on our website (for example, name-surname, e-mail address, contact and address information, etc.) and the requested services are provided or, if you have your approval, e-bulletin, marketing-oriented notifications etc. We may send newsletters. We will also need some of your personal information, such as order details, visit details, billing and delivery addresses. Apart from this information, aggregate statistical data such as visit frequency and times, ordering times and internet protocol address (IP) and last login IP address at the time of order, statistics of product pages visited and products ordered, analyze and understand visitor movements and preferences. collected and used.</li>
	  							<li>Your personal data, if you have consent; It can be processed for the purpose of market research, planning and statistical analysis.</li>
	  							<li>We may process your personal data to ensure the security of users and our website.</li>
	  						</ol>
	  					</p>
	  					<p><b>Transmission of Personal Data to Others</b></p>
	  					<p>Your personal data will not be shared with third parties without your consent or except as stipulated in the legislation, except as specified in this Privacy Policy.</p>
	  					<p>Third parties that provide support services for <b>Serenay</b> (information processing service providers, hosting or operating our sites; service providers analyzing data, service providers helping to deliver products and services to you, providing customer services) , service providers that manage payments, etc.). Relevant third parties providing support services provide the right to access the relevant personal data necessary to perform the said service. In this case, the third parties that provide the support service in question will only use your information to provide services on behalf of <b>Serenay</b> and in accordance with <b>Serenay</b> instructions, to keep this information confidential within the framework of their contractual obligations and not to use them for any other purpose. will be liable.</p>
	  					<p>Bazı durumlarda kişisel olmayan ve anonim hale getirilmiş veriler, örneğin web sitemize belirli bir zaman periyodu içerisinde kaç ziyaret yapıldığının paylaşımı söz konusu olabilir.</p>
	  					<p>Data Retention Period</p>
	  					<p><b>Serenay</b> will keep your personal data in a safe and protected environment for as long as it is suitable for the purposes of obtaining the said data and/or for a certain period of time stipulated in the relevant legislation.</p>
	  					<p>Security of Personal Data</p>
	  					<p><b>Serenay</b> provides necessary and reasonable protections to protect the confidentiality, security and integrity of personal data, works hard to protect such data against any loss, abuse or change and takes all necessary precautions.</p>
	  					<p>The information received over the site is carried with a technology called SSL (Secure Socket Layer), which provides secure information transfer. On the pages where you transfer your payment information on the site, you will see a lock or key picture on the far right (depending on the browser you use) in the address line of your browser, and the first letters of the address in this address line have changed from http to https. If you see them, you can be sure that you are on the secure servers of our site and/or mobile application.</p>
	  					<p>We generally store your personal data in <b>Serenay</b> databases or in databases provided by our service providers.</p>
	  					<p><b>Serenay</b>, contractual partners who have access to your information in order to provide you with the necessary service, are obliged to keep this information confidential within the framework of their contractual obligations and not to use them for any other purpose.</p>
	  					<p>Security of information and integrity of data</p>
	  					<p>Security of information and integrity of data</p>
	  					<p>We confirm that we have taken technical and organizational measures to protect your personal data against accidental or unlawful destruction, loss, alteration, all unlawful unauthorized use, disclosure and access that may occur during data transfer over the computer network, and other forms of processing and abuse.</p>
	  					<p>Personal data and children</p>
	  					<p>The services on the site are not intended for children. Except as permitted by the relevant legislation, personal data cannot be knowingly requested or obtained from or about children through websites.</p>
	  					<p>Changes to Our Privacy Policy</p>
	  					<p><b>Serenay</b> reserves the right to make changes to the Privacy Policy without prior notice in order to keep the privacy and data protection principles up to date and in line with the relevant legislation. If we decide to change the Privacy Policy, the changed policy will be posted on our website.</p>
	  					<p><b>Your rights</b></p>
	  					<p>You have the right to fully or partially waive any consent you have given regarding the processing of your personal data. In addition, whether your personal data is processed; requesting information about it if it has been processed, which of your personal data is processed; the purpose of processing and whether they are used for their intended purpose; to learn whether the information is transferred to third parties at home or abroad, and if so, these third parties or categories of persons; correction of your personal data if it is incomplete or inaccurate; to be updated in case of change; deletion or destruction of your personal data in the event that the reasons for processing disappear; to request that the correction/deletion/destruction processes be notified to the third parties to whom your personal data has been transferred; Your right to object to the emergence of a result against you by analyzing the processed personal data exclusively through automatic systems and to demand the compensation of the damage in case you suffer damage due to the processing of personal data in violation of the legislation, is the right of <b>Serenay</b> from the legislation and this Policy. are available, without prejudice to the arising rights. We reserve the right to refuse requests that are unreasonably repetitive, require a disproportionate level of technical effort, endanger the privacy of others, or are otherwise excessively difficult.</p>
	  					<p>In this context, in order to exercise your above-mentioned rights, you can send any request to destek@serenay.net.tr, including the necessary information to determine your identity and contact information, and your explanations about your right that you request to exercise from the rights specified in Article 11 of the KVKK, or you can apply to the other communication methods specified below.</p>
	  					<p><b>Address info</b></p>
	  					<p>
	  						<ul>
	  							<li>Konak, Elit Park, Kudret Sk. No: 12 D:Kat 3 Daire 17, 16140 <b>Nilüfer/Bursa</b></li>
	  							<li>Telephone: +90 224 413 86 76</li>
	  							<li>E-mail: destek@serenay.net.tr</li>
	  						</ul>
	  					</p>
	  					<p><b>Cookie Policy</b></p>
	  					<p>Date of update: 22.06.2022</p>
	  					<p>What are cookies?</p>
	  					<p>Today, almost every website uses cookies. Like most websites, <b>Serenay</b> also uses cookies to provide you with a better, faster and safer experience.</p>
	  					<p>Cookies are a kind of cookies left on the computer by any website. It allows computers to be recognized when the site is visited again.</p>
	  					<p>Cookies help the website remember information about your visit (for example, your preferred language and other settings). It can provide convenience on your next visit and make the site more useful.</p>
	  					<p><b>Serenay</b>'s Use of Cookies</p>
	  					<p>By visiting the Site, you accept that <b>Serenay</b> places the cookies necessary for the use of the Site on your device.</p>
	  					<p><b>Serenay</b> collects information through cookies in line with this Privacy Policy;</p>
	  					<p>Determining the usage habits and preferences of the visitors so that the site you visit can provide better service,</p>
	  					<p>Determining and collecting the technical data needed to increase the user experience on the site,</p>
	  					<p>It uses information such as the IP address of the visitors in order to fulfill its legal obligations in accordance with the Law No. 5651 on the Regulation of Broadcasts Made on the Internet and Combating the Crimes Committed Through These Broadcasts and the Regulation on the Procedures and Principles Regarding the Regulation of Broadcasts on the Internet.</p>
	  					<p>If you do not want <b>Serenay</b> to place cookies on your device, you can refuse the use of cookies through your browsers settings or options. In this case, you may not be able to use some areas of the Site properly.</p>
	  					<p><b>Serenay</b> may change this Cookie Policy when she deems it necessary. In cases where the Cookie Policy is changed, it will be effective from the update date at the top of the page. We recommend that you review the Cookie Policy from time to time to obtain information about the use of cookies.</p>
	        	</article>
	        </div>
        </div>        
      </div>
    </div>
  </Layout>
)

export default Security
